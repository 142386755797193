import Logger from '../../Logging';
import ReduxConnector from '../ReduxConnector';

import { setAreTotalDevicesChanged, setMediaInformation, setNewLocalMediaStream, updateVideoResolutionObject, setNewMediaDevice, setVideoPermission, setAudioPermission, setIsProcessing } from '../../../Redux/Actions/mediaActions';


const INFO = 'Utilities/Gnural/LocalMediaHandler';

class LocalMediaHandler {
	constructor() {
		this.init = this.init.bind(this);
		this.promptLocalMedia = this.promptLocalMedia.bind(this);
		this.getLocalMediaInformation = this.getLocalMediaInformation.bind(this);
		this.updateProperties = this.updateProperties.bind(this);
		this.StartLocalMedia = this.StartLocalMedia.bind(this);
		this.StopLocalMedia = this.StopLocalMedia.bind(this);
		this.getLocalMediaStream = this.getLocalMediaStream.bind(this);
		this.getResolutions = this.getResolutions.bind(this);
		this.awaitMutex = this.awaitMutex.bind(this);
		this.onDevicesChanged = this.onDevicesChanged.bind(this);
		this.mutex = false;
		this.startLocalMediaMutex = false;
		this.isInitialized = false;

		this.props = {
			currentAudioInput: {},
			currentAudioOutput: {},
			currentVideoInput: {},
			currentVideoResolution: {},
			videoResolutionObject: {},
			autoGainControl: true,
			voiceOptimization: true,
			useSoftwareEncoding: false
		};
		this.localStream = undefined;

		if (navigator?.mediaDevices)
		{
			navigator.mediaDevices.ondevicechange = this.onDevicesChanged;
		}
	}

	possibleResolutionList = [
		{width: 1920, height:1080, friendlyName: '1920x1080'},
		{width: 1280, height: 720, friendlyName: '1280x720'},
		{width: 960, height: 720, friendlyName: '960x720'},
		{width: 960, height: 540, friendlyName: '960x540'},
		{width: 640, height: 480, friendlyName: '640x480'},
		{width: 640, height: 360, friendlyName: '640x360'},
		{width: 320, height: 180, friendlyName: '320x180'}
	];

	async onDevicesChanged() {
		ReduxConnector.dispatch(setAreTotalDevicesChanged(true));
	}

	async updateProperties(newProperties) {
		if (!newProperties.hasSetBrowserInformation) {
			return;
		}
		const oldProperties = this.props;
		this.props = newProperties || { 
			currentAudioInput: {},
			currentAudioOutput: {},
			currentVideoInput: {},
			autoGainControl: true,
			voiceOptimization: true,
			useSoftwareEncoding: false
		};

		let newAudioInputDevice = this.props.currentAudioInput.deviceId || (oldProperties ? oldProperties.currentAudioInput.deviceId : '');
		let newAudioOutputDevice = this.props.currentAudioOutput.deviceId || (oldProperties ? oldProperties.currentAudioOutput.deviceId : '');
		let newVideoInputDevice = this.props.currentVideoInput.deviceId || (oldProperties ? oldProperties.currentVideoInput.deviceId : '');
		let newVideoResolution = this.props.currentVideoResolution.friendlyName;
		
		let shouldRestartMedia = false;

		if (!oldProperties || oldProperties.voiceOptimization !== this.props.voiceOptimization) {
			localStorage.setItem('DefaultVoiceOptimization', this.props.voiceOptimization);
			shouldRestartMedia = true;
		}
		if (!oldProperties || oldProperties.autoGainControl !== this.props.autoGainControl) {
			localStorage.setItem('DefaultAutoGainControl', this.props.autoGainControl);
			shouldRestartMedia = true;
		}

		if (!oldProperties || oldProperties.useSoftwareEncoding !== this.props.useSoftwareEncoding) {
			localStorage.setItem('DefaultUseSoftwareEncoding', this.props.useSoftwareEncoding);
			window.UseSoftwareEncoding = this.props.useSoftwareEncoding;
			shouldRestartMedia = true;
		}

		if (!oldProperties || oldProperties.currentAudioInput.deviceId !== newAudioInputDevice) {
			localStorage.setItem('DefaultAudioInputDevice', newAudioInputDevice);
			shouldRestartMedia = true;
		}
		if (!oldProperties || oldProperties.currentAudioOutput.deviceId !== newAudioOutputDevice) {
			localStorage.setItem('DefaultAudioOutputDevice', newAudioOutputDevice);
			// Do something else;
		}

		if (newVideoInputDevice && (!oldProperties || oldProperties.currentVideoResolution.friendlyName !== newVideoResolution)) {
			localStorage.setItem('DefaultVideoResolution-' + newVideoInputDevice, newVideoResolution);
			shouldRestartMedia = true;
		}

		if ((oldProperties.currentVideoResolution || {}).friendlyName !== newVideoResolution) {
			shouldRestartMedia = true;
		}

		if (!oldProperties || oldProperties.currentVideoInput.deviceId !== newVideoInputDevice) {
			localStorage.setItem('DefaultVideoInputDevice', newVideoInputDevice);
			shouldRestartMedia = true;
			let videoResolutionList = this.props.videoResolutionObject[newVideoInputDevice];
			if (!videoResolutionList) {
				videoResolutionList = await this.getResolutions(newVideoInputDevice);
				ReduxConnector.dispatch(updateVideoResolutionObject(videoResolutionList, newVideoInputDevice));
				//shouldRestartMedia = false;
			}
			const defaultResolutionFriendly = localStorage.getItem('DefaultVideoResolution-' + newVideoInputDevice);
			let defaultResolution = (videoResolutionList || []).filter((resolution) => {
				return resolution.friendlyName === defaultResolutionFriendly;
			})[0];
			if (!defaultResolution) {
				defaultResolution = (videoResolutionList || [])[0] || {};
			}
			if (defaultResolution.friendlyName !== newVideoResolution) {
				ReduxConnector.dispatch(setNewMediaDevice('videoResolutionObject', defaultResolution));
				shouldRestartMedia = false;
			}
		}
		if (shouldRestartMedia) {
			this.StartLocalMedia();
		}

	}

	awaitMutex(mutexName) {
		return new Promise((resolve) => {
			const checkMutex = () => {
				if (this[mutexName]) {
					setTimeout(checkMutex, 800);
					return;
				}
				this[mutexName]= true;
				resolve();
			};
			checkMutex();
		});
	}

	StopLocalMedia() {
		if (this.localStream) {
			this.localStream.getVideoTracks().forEach(track => {
				if (track) {
					track.stop();
				}
			});
			this.localStream.getAudioTracks().forEach(track => {
				if (track) {
					track.stop();
				}
			});
		}
	}

	async StartLocalMedia() {
		await this.awaitMutex('startLocalMediaMutex');
		if (!this.props.currentAudioInput.deviceId || !this.props.currentVideoInput.deviceId) {
			Logger.warn(INFO, 'Invalid Audio Input or Video Input Device');
		}
		ReduxConnector.dispatch(setIsProcessing(true));

		let rtcVideoConstraints = (
			this.props.currentVideoInput.deviceId && this.props.currentVideoInput.deviceId !== 'Screenshare' 
			&& this.props.currentVideoInput.deviceId !== 'AudioOnly' ? 
				{deviceId: {exact: this.props.currentVideoInput.deviceId}} : 
				false
		);

		const rtcAudioProcessingConstraints = {
			echoCancellationType: 'browser',
			channelCount: 2,
			echoCancellation: this.props.voiceOptimization,
			noiseSuppression: this.props.voiceOptimization,
			autoGainControl: this.props.autoGainControl,
			googEchoCancellation: this.props.voiceOptimization,
			googAutoGainControl: this.props.autoGainControl,
			googExperimentalAutoGainControl: this.props.autoGainControl,
			googNoiseSuppression: this.props.voiceOptimization,
			googExperimentalNoiseSuppression: this.props.voiceOptimization,
			googHighpassFilter: this.props.voiceOptimization,
			googTypingNoiseDetection: this.props.voiceOptimization,
			googBeamforming: this.props.voiceOptimization,
			googArrayGeometry: this.props.voiceOptimization,
			googAudioMirroring: this.props.voiceOptimization,
			googNoiseReduction: this.props.voiceOptimization,
			mozNoiseSuppression: this.props.voiceOptimization,
			mozAutoGainControl: this.props.autoGainControl
		};

		const rtcAudioConstraints = (
			this.props.currentAudioInput.deviceId ? 
				{
					deviceId: {exact: this.props.currentAudioInput.deviceId}
					,...rtcAudioProcessingConstraints
				} :
				false
		);

		if (rtcVideoConstraints && this.props.currentVideoResolution && this.props.currentVideoResolution.width && this.props.currentVideoResolution.height) {
			rtcVideoConstraints.width = {exact: this.props.currentVideoResolution.width};
			rtcVideoConstraints.height = {exact: this.props.currentVideoResolution.height};
		} else if (rtcVideoConstraints) {
			rtcVideoConstraints.width = {ideal: 1280};
			rtcVideoConstraints.height = {ideal: 720};
		}

		const rtcConstraints = {
			video: rtcVideoConstraints, 
			audio: rtcAudioConstraints
		};

		try {
			if (this.localStream) {
				this.localStream.getVideoTracks().forEach(track => {
					if (track) {
						track.stop();
					}
				});
				this.localStream.getAudioTracks().forEach(track => {
					if (track) {
						track.stop();
					}
				});
			}
			this.localStream = await navigator.mediaDevices.getUserMedia(rtcConstraints);
			/*this.localStream.getAudioTracks().forEach(track => {
				if (track)
				{
					track.applyConstraints(rtcAudioProcessingConstraints);
				}
			});*/
			if (this.props.currentVideoInput && this.props.currentVideoInput.deviceId === 'Screenshare'
				&& navigator.mediaDevices.getDisplayMedia) {
				rtcVideoConstraints = {};
				if (rtcVideoConstraints && this.props.currentVideoResolution && this.props.currentVideoResolution.width && this.props.currentVideoResolution.height) {
					rtcVideoConstraints.width = this.props.currentVideoResolution.width;
					rtcVideoConstraints.height = this.props.currentVideoResolution.height;
				} else {
					rtcVideoConstraints = true;
				}
				const screenshareStream = await navigator.mediaDevices.getDisplayMedia({video: rtcVideoConstraints});
				if (screenshareStream && screenshareStream.getVideoTracks()) {
					screenshareStream.getVideoTracks().forEach(track => {
						this.localStream.addTrack(track);
					});
				}
			}
			ReduxConnector.dispatch(setNewLocalMediaStream(this.localStream));
		} catch(e) {
			Logger.error(INFO, 'Failed to get Local Media: ', e);
		}
		ReduxConnector.dispatch(setIsProcessing(false));
		this.startLocalMediaMutex = false;
	}

	getLocalMediaStream() {
		return this.localStream;
	}

	async promptLocalMedia() {
		await this.awaitMutex('startLocalMediaMutex');
		let mediaConstraints = {
			video: true,
			audio: true
		};
		let stream;
		try {
			stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
			stream.getVideoTracks().forEach(track => {
				if (track) {
					track.stop();
				}
			});
			stream.getAudioTracks().forEach(track => {
				if (track) {
					track.stop();
				}
			});
			await this.getLocalMediaInformation();
			ReduxConnector.dispatch(setVideoPermission(true));
			ReduxConnector.dispatch(setAudioPermission(true));
		} catch (e) {
			Logger.error(INFO, 'Failed to Prompt Local Media: ', e);
			Logger.warn(INFO, 'Attempting to Prompt Just Video Media');
			try {
				mediaConstraints = {
					video: true,
					audio: false
				};
				stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
				stream.getVideoTracks().forEach(track => {
					if (track) {
						track.stop();
					}
				});
				await this.getLocalMediaInformation();
				ReduxConnector.dispatch(setVideoPermission(true));
			} catch (e) {
				Logger.error(INFO, 'Failed to Prompt Just Video Media: ', e);
				if (e.name === 'NotAllowedError') {
					ReduxConnector.dispatch(setVideoPermission(false));
				}
			}
			Logger.warn(INFO, 'Attempting to Prompt Just Audio Media');
			try {
				mediaConstraints = {
					video: false,
					audio: true
				};
				stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
				stream.getAudioTracks().forEach(track => {
					if (track) {
						track.stop();
					}
				});
				ReduxConnector.dispatch(setAudioPermission(true));
			} catch (e) {
				if (e.name === 'NotAllowedError') {
					ReduxConnector.dispatch(setAudioPermission(false));
				}
				Logger.error(INFO, 'Failed to Prompt Just Audio Media: ', e);
			}
		}
		this.startLocalMediaMutex = false;
	}

	async tryMediaAsync(constraints, resolution) {
		try {
			const videoStream = await navigator.mediaDevices.getUserMedia(constraints);
			if (videoStream) {
				videoStream.getVideoTracks().forEach((track) => {
					track.stop();
				});
			}
			return resolution;
		} catch (e) {
			return null;
		}
	}

	async getResolutions(deviceId) {
		if (!deviceId || !this.props.BrowserName) {
			return [];
		}
		if (deviceId === 'Screenshare') {
			return this.possibleResolutionList;
		}

		ReduxConnector.dispatch(setIsProcessing(true));

		let possibleResolutionList = [
			{width: 1920, height:1080, friendlyName: '1920x1080'},
			{width: 1280, height: 720, friendlyName: '1280x720'},
			{width: 960, height: 720, friendlyName: '960x720'},
			{width: 960, height: 540, friendlyName: '960x540'},
			{width: 640, height: 480, friendlyName: '640x480'},
			{width: 640, height: 360, friendlyName: '640x360'},
			{width: 320, height: 180, friendlyName: '320x180'}
		];
		if (this.props.isSafari) {
			possibleResolutionList = [
				{width: 1280, height: 720, friendlyName: '1280x720'},
				{width: 640, height: 480, friendlyName: '640x480'},
				{width: 320, height: 240, friendlyName: '320x240'}
			];
		}

		const videoResolutionRequestList = [];
		const finalVideoResolutionList = [];	

		let constraint;

		try {
			let isValid = await this.tryMediaAsync({video: {deviceId: {exact: deviceId}}, audio: false}, 'PROMPT');
			if (isValid == null) {
				Logger.error(INFO, 'Media Device: ' + deviceId + ' Can Not Be Started');
				ReduxConnector.dispatch(setIsProcessing(false));
				return [];
			}
		} catch {
			ReduxConnector.dispatch(setIsProcessing(false));
			return [];
		}
		
		possibleResolutionList.forEach(resolution => {
			constraint = {
				video: {
					deviceId: {exact: deviceId},
					width: {exact: resolution.width},
					height: {exact: resolution.height}
				},
				audio: false
			};
			videoResolutionRequestList.push(this.tryMediaAsync(constraint, resolution));
		});

		let response;
		for (let index = 0; index < videoResolutionRequestList.length; index++) {
			try {
				response = await videoResolutionRequestList[index];
			} catch {
				response = null;
			}
			if (response) {
				finalVideoResolutionList.push(response);
			}
		}
		
		ReduxConnector.dispatch(setIsProcessing(false));
		return finalVideoResolutionList;	
	}

	async getLocalMediaInformation() {
		try {
			const mediaDevices = await navigator.mediaDevices.enumerateDevices();
			const audioInputList = [];
			const audioOutputList = [];
			const videoInputList = [];
			
			let audioOutputCounter = 1;
			let audioInputCounter = 1;
			let videoInputCounter = 1;
			mediaDevices.forEach(device => {
				if (!device || !device.kind) {
					return;
				}
				switch(device.kind) {
				case 'audioinput': {
					if (!device.label) {
						return audioInputList.push({
							deviceId: device.deviceId,
							groupId: device.groupId,
							kind: device.kind,
							label: 'Audio Input Device ' + audioInputCounter++
						});
					}
					return audioInputList.push(device);
				}
				case 'audiooutput': {
					if (!device.label) {
						return audioOutputList.push({
							deviceId: device.deviceId,
							groupId: device.groupId,
							kind: device.kind,
							label: 'Audio Output Device ' + audioOutputCounter++
						});
					}
					return audioOutputList.push(device);
				}
				case 'videoinput': {
					if (!device.label) {
						return videoInputList.push({
							deviceId: device.deviceId,
							groupId: device.groupId,
							kind: device.kind,
							label: 'Video Input Device ' + videoInputCounter++
						});
					}
					return videoInputList.push(device);
				}
				default:
					return;
				}
			});
			if (this.props.isChrome && Number.parseInt(this.props.BrowserVer.split('.')[0]) >= 72) {
				videoInputList.push({deviceId: 'Screenshare', groupId: 'Screenshare', kind: 'videoinput', label: 'Screenshare'});
			}
			videoInputList.push({deviceId: 'AudioOnly', groupId: 'AudioOnly', kind: 'videoinput', label: 'Audio Only'});

			const defaultAudioInputDeviceID = localStorage.getItem('DefaultAudioInputDevice');
			const defaultAudioOutputDeviceID = localStorage.getItem('DefaultAudioOutputDevice');
			const defaultVideoInputDeviceID = localStorage.getItem('DefaultVideoInputDevice');

			if (localStorage.getItem('DefaultAutoGainControl') === null)
			{
				localStorage.setItem('DefaultAutoGainControl', 'true');
			}
			if (localStorage.getItem('DefaultVoiceOptimization') === null)
			{
				localStorage.setItem('DefaultVoiceOptimization', 'true');
			}

			if (localStorage.getItem('DefaultUseSoftwareEncoding') === null)
			{
				localStorage.setItem('DefaultUseSoftwareEncoding', 'false');
			}

			const defaultAutoGainControl = (localStorage.getItem('DefaultAutoGainControl') === 'true');
			const defaultVoiceOptimization = (localStorage.getItem('DefaultVoiceOptimization') === 'true');
			const useSoftwareEncoding = (localStorage.getItem('DefaultUseSoftwareEncoding') === 'true');
			window.UseSoftwareEncoding = useSoftwareEncoding;

			const defaultAudioInputDevice = audioInputList.filter(device => device.deviceId === defaultAudioInputDeviceID)[0] || audioInputList[0] || {};
			const defaultAudioOutputDevice = audioOutputList.filter(device => device.deviceId === defaultAudioOutputDeviceID)[0] || audioOutputList[0] || {};
			const defaultVideoInputDevice = videoInputList.filter(device => device.deviceId === defaultVideoInputDeviceID)[0] || videoInputList[0] || {};
		
			const videoResolutionObject = {};
			let defaultVideoResolution = {};
			if (defaultVideoInputDevice) {
				const videoResolutions = await this.getResolutions(defaultVideoInputDevice.deviceId);
				defaultVideoResolution = localStorage.getItem('DefaultVideoResolution-' + defaultVideoInputDevice.deviceId);
				if (!defaultVideoResolution && videoResolutions.length >= 1) {
					defaultVideoResolution = videoResolutions[0];
				}
				videoResolutionObject[defaultVideoInputDevice.deviceId] = videoResolutions;
			}
			if (!defaultVideoResolution) {
				defaultVideoResolution = {};
			}

			ReduxConnector.dispatch(setMediaInformation({
				audioInputList,
				audioOutputList,
				videoInputList,
				videoResolutionObject,
				currentAudioInput: defaultAudioInputDevice,
				currentAudioOutput: defaultAudioOutputDevice,
				currentVideoInput: defaultVideoInputDevice,
				currentVideoResolution: defaultVideoResolution,
				autoGainControl: defaultAutoGainControl,
				voiceOptimization: defaultVoiceOptimization,
				useSoftwareEncoding: useSoftwareEncoding
			}));

		} catch (e) {
			Logger.error(INFO, 'Failed to get Local Media Information: ', e);
		}
	}

	async refreshLocalMedia() {
		if (!this.isInitialized) {
			return;
		}
		if (this.mutex) {
			return;
		}
		this.mutex = true;
		this.StopLocalMedia();
		await this.getLocalMediaInformation();
		await this.StartLocalMedia();
		ReduxConnector.dispatch(setAreTotalDevicesChanged(false));
		this.mutex = false;
	}

	async init() {
		if (this.mutex) {
			return;
		}
		this.mutex = true;
		await this.promptLocalMedia();
		//await this.getLocalMediaInformation();
		this.mutex = false;
		this.isInitialized = true;
	}
}

function neededStoreVariables(store) {
	return {...store.media, ...store.sessionInformation};
}

const Handler = new LocalMediaHandler();

ReduxConnector.connect(neededStoreVariables, Handler.updateProperties);

export default Handler;