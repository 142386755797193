import React from 'react';
import style from './MediaPanel.module.css';
import ToggleSwitch from '../../ToggleSwitch';

function SettingsMenuPresentation(props) {
	function precomputedOnUpdateSelectGeneric(selectType) {
		return (event) => {props.onUpdateSelectGeneric(selectType, event);};
	}
	return (
		<div className={[style.SettingsMenuPresentationWrapperOuter].join(' ')}>
			<div className={[style.SettingsMenuPresentationWrapperInner].join(' ')}>
				<select value={(props.currentVideoInput ? props.currentVideoInput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('videoInputList')}>
					<option value={undefined} disabled>- Select Video Input Device -</option>
					{props.videoInputList.map(input => {
						return <option value={input.deviceId} key={input.deviceId}>{input.label}</option>;
					})}
				</select>
				<select value={props.currentVideoResolution ? props.currentVideoResolution.friendlyName : ''} onChange={props.onUpdateVideoResolution}>
					<option value={undefined} disabled>- Select Video Resolution -</option>
					{props.resolutionList.map(resolution => {
						return <option value={resolution.friendlyName} key={'Input-Selector-' + resolution.friendlyName}>{resolution.friendlyName}</option>;
					})}
				</select>
				<select value={(props.currentAudioInput ? props.currentAudioInput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('audioInputList')}>]
					<option value={undefined} disabled>- Select Audio Input Device -</option>
					{props.audioInputList.map(input => {
						return <option value={input.deviceId} key={input.deviceId}>{input.label}</option>;
					})}
				</select>
				<select value={(props.currentAudioOutput ? props.currentAudioOutput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('audioOutputList')}>
					<option value={undefined} disabled>- Select Audio Output Device -</option>
					{props.audioOutputList.map(output => {
						return <option value={output.deviceId} key={output.deviceId}>{output.label}</option>;
					})}
				</select>
				<div className={style.ToggleSurfaceWrapper}>
					{
						<React.Fragment>
							<ToggleSwitch 
								Label={'Automatic Gain'}
								IsActive={props.autoGainControl}
								ToggleHandler={props.onToggleAutoGainControl}/>
							<ToggleSwitch
								Label={'Voice Optimization'}
								IsActive={props.voiceOptimization}
								ToggleHandler={props.onToggleVoiceOptimization}/>
						</React.Fragment>
					}
					{(props.isChrome || props.isEdge ?
						<React.Fragment>
							<ToggleSwitch
								Label={'Software Encoding'}
								IsActive={props.useSoftwareEncoding}
								ToggleHandler={props.onToggleUseSoftwareEncoding}/>
						</React.Fragment>
						: null)}
					{(props.UserId ? 
						/*<div className='AutoAnswerWrapper'>
							<input type='checkbox' name='AutoAnswer' onChange={props.onToggleAutoAnswer} value='Auto Answer' checked={props.autoAnswer}/>
							<label htmlFor='AutoAnswer'>Auto Answer</label>
						</div>*/
						<React.Fragment>
							<ToggleSwitch
								Label={'Auto Answer'}
								IsActive={props.autoAnswer}
								ToggleHandler={props.onToggleAutoAnswer}/>
						</React.Fragment>
						:
						null) 
					}
				</div>
				<div className={[style.SettingsMenuButtonWrapper].join(' ')}>
					{ props.areTotalDevicesChanged ? 
						<div className={[style.MediaRefreshButton, style.MediaRefreshButtonWarning, 'Button'].join(' ')} onClick={props.onRefreshLocalMedia}> Get New Devices! </div>
						:
						<div className={[style.MediaRefreshButton, 'Button Primary'].join(' ')} onClick={props.onRefreshLocalMedia}> Refresh </div>
					}
				</div>
			</div>
		</div>
	);
}

export default SettingsMenuPresentation;

/*
	}
	return (
		<div className={[style.SettingsMenuPresentationWrapperOuter].join(' ')}>
			<div className={[style.SettingsMenuPresentationWrapperInner].join(' ')}>
				<select value={(props.currentVideoInput ? props.currentVideoInput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('videoInputList')}>
					<option value={undefined} disabled>- Select Video Input Device -</option>
					{props.videoInputList.map(input => {
						return <option value={input.deviceId} key={input.deviceId}>{input.label}</option>;
					})}
				</select>
				<select value={props.currentVideoResolution ? props.currentVideoResolution.friendlyName : ''} onChange={props.onUpdateVideoResolution}>
					<option value={undefined} disabled>- Select Video Resolution -</option>
					{props.resolutionList.map(resolution => {
						return <option value={resolution.friendlyName} key={'Input-Selector-' + resolution.friendlyName}>{resolution.friendlyName}</option>;
					})}
				</select>
				<select value={(props.currentAudioInput ? props.currentAudioInput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('audioInputList')}>]
					<option value={undefined} disabled>- Select Audio Input Device -</option>
					{props.audioInputList.map(input => {
						return <option value={input.deviceId} key={input.deviceId}>{input.label}</option>;
					})}
				</select>
				<select value={(props.currentAudioOutput ? props.currentAudioOutput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('audioOutputList')}>
					<option value={undefined} disabled>- Select Audio Output Device -</option>
					{props.audioOutputList.map(output => {
						return <option value={output.deviceId} key={output.deviceId}>{output.label}</option>;
					})}
				</select>
				<div className={[style.SettingsMenuButtonWrapper].join(' ')}>
					<div className={[style.MediaRefreshButton, (props.isMuted ? style.active : null)].join(' ')} onClick={props.onToggleLocalMediaStreamMute}>Mute</div>
					<div className={[style.MediaRefreshButton].join(' ')} onClick={props.onRefreshLocalMedia}> Refresh </div>
				</div>
				{
					<div className={[style.SettingsMenuButtonWrapper].join(' ')}>
						<div className={[style.MediaRefreshButton, (props.autoGainControl ? style.active : null)].join(' ')} onClick={props.onToggleAutoGainControl}>Automatic Gain</div>
						<div className={[style.MediaRefreshButton, (props.voiceOptimization ? style.active : null)].join(' ')} onClick={props.onToggleVoiceOptimization}>Voice Optimization</div>
					</div>
				}
				{(props.isChrome || props.isEdge ?
					<div className={[style.SettingsMenuButtonWrapper].join(' ')}>
						<div className={[style.MediaRefreshButton, (props.useSoftwareEncoding ? style.active : null)].join(' ')} onClick={props.onToggleUseSoftwareEncoding}>Software Encoding</div>
						<div className={[style.MediaRefreshButton].join(' ')}></div>
					</div>
					: null)}
				{(props.UserId ? 
					/*<div className='AutoAnswerWrapper'>
						<input type='checkbox' name='AutoAnswer' onChange={props.onToggleAutoAnswer} value='Auto Answer' checked={props.autoAnswer}/>
						<label htmlFor='AutoAnswer'>Auto Answer</label>
					</div>
					<div className={[style.SettingsMenuButtonWrapper].join(' ')}>
						<div className={[style.MediaRefreshButton, (props.autoAnswer ? style.active : null)].join(' ')} onClick={props.onToggleAutoAnswer}>Auto Answer</div>
						<div className={[style.MediaRefreshButton].join(' ')}/>
					</div>
					: <div className={[style.SettingsMenuButtonWrapper].join(' ')}></div>)}
			</div>
		</div>
	);
}

export default SettingsMenuPresentation;*/
