import GnuralRTC from '../../Utilities/Gnural/GnuralRTC';


export function setMediaInformation(information) {
	return {type: 'SET_MEDIA_INFORMATION', payload: information};
}

export function setNewLocalMediaStream(stream) {
	return (dispatch) => {
		GnuralRTC.Instance.RemoveLocalStreams(null, false);
		GnuralRTC.Instance.addLocalStream(stream);
		dispatch({type: 'SET_NEW_LOCAL_MEDIA_STREAM', payload: (stream ? stream.id: '')});
	};
}

export function toggleLocalMediaStreamMute() {
	return {type: 'TOGGLE_LOCAL_MEDIA_STREAM_MUTE'};
}

export function toggleAutoGainControl() {
	return {type: 'TOGGLE_AUTO_GAIN_CONTROL'};
}

export function toggleVoiceOptimization() {
	return {type: 'TOGGLE_VOICE_OPTIMIZATION'};
}

export function toggleUseSoftwareEncoding() {
	return {type: 'TOGGLE_USE_SOFTWARE_ENCODING'};
}

export function setNewMediaDevice(mediaType, mediaObject) {
	return {
		type: 'SET_NEW_MEDIA_DEVICE',
		payload: {
			mediaType,
			mediaObject
		}
	};
}

export function updateVideoResolutionObject(videoResolutionList, deviceId) {
	return {
		type: 'UPDATE_VIDEO_RESOLUTION_OBJECT',
		payload: {
			videoResolutionList,
			deviceId
		}
	};
}

export function refreshLocalMedia() {
	return {type: 'REFRESH_LOCAL_MEDIA'};
}

export function onRemoteStream(stream) {
	return {
		type: 'ON_REMOTE_STREAM',
		payload: stream
	};
}

export function setVideoPermission(Value) {
	return {
		type: 'ON_SET_VIDEO_PERMISSION',
		payload: Value
	};
}

export function setAudioPermission(Value) {
	return {
		type: 'ON_SET_AUDIO_PERMISSION',
		payload: Value
	};
}

export function setShouldConfigureMedia(Value) {
	return {
		type: 'ON_SET_SHOULD_CONFIGURE_MEDIA',
		payload: Value
	};
}

export function setIsProcessing(value)
{
	return {
		type: 'ON_SET_IS_PROCESSING',
		payload: value
	};
}

export function setAreTotalDevicesChanged(areTotalDevicesChanged)
{
	return {
		type: 'SET_ARE_TOTAL_DEVICES_CHANGED',
		payload: areTotalDevicesChanged
	};
}
