import React from 'react';
import { connect } from 'react-redux';
import { setNewMediaDevice, refreshLocalMedia, toggleLocalMediaStreamMute, toggleVoiceOptimization, toggleAutoGainControl, toggleUseSoftwareEncoding } from '../../../../Redux/Actions/mediaActions';
import { setAutoAnswer } from '../../../../Redux/Actions/callActions';

import SettingsMenuPresentation from './SettingsMenuPresentation';
import CustomVideo from '../../../CustomVideo';
import LocalMediaHandler from '../../../../Utilities/Gnural/LocalMediaHandler';

import style from './MediaPanel.module.css';

function mapStateToProps(store) {
	return ({
		audioInputList: store.media.audioInputList,
		audioOutputList: store.media.audioOutputList,
		videoInputList: store.media.videoInputList,
		videoResolutionObject: store.media.videoResolutionObject,
		currentAudioInput: store.media.currentAudioInput,
		currentAudioOutput: store.media.currentAudioOutput,
		currentVideoInput: store.media.currentVideoInput,
		currentVideoResolution: store.media.currentVideoResolution,
		autoGainControl: store.media.autoGainControl,
		voiceOptimization: store.media.voiceOptimization,
		useSoftwareEncoding: store.media.useSoftwareEncoding,
		localMediaStreamID: store.media.localMediaStreamID,
		isProcessing: store.media.isProcessing,
		isChrome: store.sessionInformation.isChrome,
		isEdge: store.sessionInformation.isEdge,
		autoAnswer: store.call.AutoAnswer,
		UserId: store.user.UserId,
		isMuted: store.media.isMuted,
		areTotalDevicesChanged: store.media.areTotalDevicesChanged
	});
}

class MediaPanel extends React.Component {
	constructor(props) {
		super(props);
		// Do stuff with binding
		this.onUpdateSelectGeneric = this.onUpdateSelectGeneric.bind(this);
		this.onUpdateVideoResolution = this.onUpdateVideoResolution.bind(this);
		this.onRefreshLocalMedia = this.onRefreshLocalMedia.bind(this);
		this.onToggleAutoAnswer = this.onToggleAutoAnswer.bind(this);
		this.onToggleLocalMediaStreamMute = this.onToggleLocalMediaStreamMute.bind(this);
		this.onToggleAutoGainControl = this.onToggleAutoGainControl.bind(this);
		this.onToggleVoiceOptimization = this.onToggleVoiceOptimization.bind(this);
		this.onToggleUseSoftwareEncoding = this.onToggleUseSoftwareEncoding.bind(this);

		this.state = {
			localStream: undefined
		};
	}

	componentDidMount() {
		this.setState({
			localStream: LocalMediaHandler.getLocalMediaStream()
		});
	}

	componentDidUpdate(nextProps) {
		if (nextProps.localMediaStreamID !== this.props.localMediaStreamID) {
			this.setState({
				localStream: LocalMediaHandler.getLocalMediaStream()
			});
		}
	}

	onRefreshLocalMedia() {
		this.props.dispatch(refreshLocalMedia());
	}

	onUpdateSelectGeneric(selectType, event) {
		if (!event || !event.target || !selectType) {
			return;
		}
		const currentlySelectedObject = event.target.options[event.target.selectedIndex].value;
		if (!currentlySelectedObject) {
			return;
		}
		const desiredItem = this.props[selectType].filter(objects => {
			return objects.deviceId === currentlySelectedObject;
		});
		if (!desiredItem[0]) {
			return;
		}
		this.props.dispatch(setNewMediaDevice(selectType, desiredItem[0]));
	}

	onUpdateVideoResolution(event) {
		if (!event || !event.target) {
			return;
		}

		const currentlySelectedResolution = event.target.options[event.target.selectedIndex];
		if (!currentlySelectedResolution) {
			return;
		}
		const desiredResolution = this.props.videoResolutionObject[this.props.currentVideoInput.deviceId].filter(
			(resolution) => {
				return resolution.friendlyName === currentlySelectedResolution.value;
			})[0];
		if (!desiredResolution) {
			return;
		}
		this.props.dispatch(setNewMediaDevice('videoResolutionObject', desiredResolution));
	}

	onToggleAutoAnswer() {
		this.props.dispatch(setAutoAnswer(!this.props.autoAnswer));
	}

	onToggleLocalMediaStreamMute() {
		this.props.dispatch(toggleLocalMediaStreamMute());
	}

	onToggleVoiceOptimization() {
		this.props.dispatch(toggleVoiceOptimization());
	}

	onToggleAutoGainControl() {
		this.props.dispatch(toggleAutoGainControl());
	}

	onToggleUseSoftwareEncoding() {
		this.props.dispatch(toggleUseSoftwareEncoding());
	}

	render() {
		return (
			<div className={style.MediaPanelWrapper}>
				<div className={style.LocalVideoWrapper}>
					<CustomVideo
						className={[style.LocalVideo].join(' ')}
						srcObject={this.state.localStream}
						muted={true}
						shouldContainFraming={true}
						VideoFramingStyle={style.VideoFraming}
					/>
					{this.props.isProcessing ? 
						<div className={style.IsProcessingWrapper}>
							<div className={style.IsProcessingGraphic}>
								<div className={style.IsProcessingGraphicInner}>Loading</div>
							</div>
						</div>
						:
						null
					}
				</div>
				<div className={style.HeadingWrapper}>
					<div className={style.MediaSettingsLabel}>Local Media Settings:</div>
				</div>
				<SettingsMenuPresentation
					currentVideoInput={this.props.currentVideoInput}
					videoInputList={this.props.videoInputList}
					currentAudioInput={this.props.currentAudioInput}
					audioInputList={this.props.audioInputList}
					currentAudioOutput={this.props.currentAudioOutput}
					audioOutputList={this.props.audioOutputList}
					resolutionList={
						(this.props.currentVideoInput && this.props.videoResolutionObject[this.props.currentVideoInput.deviceId] ? 
							this.props.videoResolutionObject[this.props.currentVideoInput.deviceId] : 
							[]
						)
					}
					currentVideoResolution={this.props.currentVideoResolution}
					isChrome={this.props.isChrome}
					isEdge={this.props.isEdge}
					onUpdateSelectGeneric={this.onUpdateSelectGeneric}
					onUpdateVideoResolution={this.onUpdateVideoResolution}
					onRefreshLocalMedia={this.onRefreshLocalMedia}
					onAutoforwardChange={this.onAutoforwardChange}
					onToggleAutoAnswer={this.onToggleAutoAnswer}
					onToggleLocalMediaStreamMute={this.onToggleLocalMediaStreamMute}
					onToggleAutoGainControl={this.onToggleAutoGainControl}
					onToggleVoiceOptimization={this.onToggleVoiceOptimization}
					onToggleUseSoftwareEncoding={this.onToggleUseSoftwareEncoding}
					autoGainControl={this.props.autoGainControl}
					voiceOptimization={this.props.voiceOptimization}
					useSoftwareEncoding={this.props.useSoftwareEncoding}
					currentAutoForward={this.props.autoForward}
					autoAnswer={this.props.autoAnswer}
					UserId={this.props.UserId}
					isMuted={this.props.isMuted}
					areTotalDevicesChanged={this.props.areTotalDevicesChanged}
				/>
			</div>
		);
	}
}
//<button className='custom-secondary-button' onClick={this.openSettingsModal}> Settings </button>

export default connect(mapStateToProps)(MediaPanel);